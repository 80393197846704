import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { createModelCode } from "../model-code/ModelCode";
import { SdMux } from "../sd-mux/SdMux";

/**
 * # Report
 * 
 * Report을 설명하세요.
 */
export const Report = types
  .model("Report")
  .props({
    chkDt: types.optional(types.string, ''),
    rprtCycl: types.optional(types.maybeNull(createModelCode()), null),
    rprtType: types.optional(types.maybeNull(createModelCode()), null),
    regDt: types.optional(types.string, ''),

    chkDtSt: types.optional(types.maybeNull(types.string), null),
    chkDtEd: types.optional(types.maybeNull(types.string), null),

    bizSendDt: types.optional(types.maybeNull(types.string), null),
    bizEmailAddrs: types.optional(types.maybeNull(types.array(types.string)), []),
    bizHtml: types.optional(types.maybeNull(types.string), null),
    bizExcelYn: types.optional(types.maybeNull(types.boolean), false),
    
    oprSendDt: types.optional(types.maybeNull(types.string), null),
    oprEmailAddrs: types.optional(types.maybeNull(types.array(types.string)), []),
    oprHtml: types.optional(types.maybeNull(types.string), null),
    oprExcelYn: types.optional(types.maybeNull(types.boolean), false),

    sendDt: types.optional(types.maybeNull(types.string), null),
    html: types.optional(types.maybeNull(types.string), null),
    emailAddrs:  types.optional(types.maybeNull(types.array(types.string)), []),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IReport;
      (Object.keys(newProps) as TReportKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TReport = Instance<typeof Report>;
type TReportSnapshot = SnapshotOut<typeof Report>

export interface IReport extends TReport {}
export type TReportKeys = keyof TReportSnapshot & string;
export interface IReportSnapshot extends TReportSnapshot {}
export const createReport = () => 
  types.optional(Report, {
    
  } as TReport);

  