export const ROWS_PER_PAGE = {
  Options: [50, 100, 150, 200],
  OptionsDialog: [30, 50, 100],
};

export const SCROLL_HEIGHT = 370;

export const PLACE_HOLDER = {
  common: {
    select: '선택',
  },
};

export const ALERT_MSG = {
  common: {
    insert: '등록하시겠습니까?',
    update: '수정하시겠습니까?',
    delete: '삭제하시겠습니까?',
    save: '저장하시겠습니까?',
    loginError: '로그인 에러',
    noFile: '파일을 첨부하세요',
    uploadInfo: '장비 업로드가 시작됩니다.',
    otpExpired: 'OTP시간이 만료되었습니다.',
  },
  configurationManagement: {
    channelMgmt: {
      registerChannelLineTitle: '채널 라인업 등록',
      registerChannelLineFileEmpty: '파일을 업로드하세요.',
      registeringChannelLine: '채널 라인업 등록 진행중 입니다...',
      successRegisteringChannelLine: '채널 라인업 등록에 성공하였습니다.',
      failureRegisteringChannelLine: '채널 라인업 등록에 실패하였습니다.',
      registerChannelLineMsgFirst:
        "1. 존재하지 않은 'PP사', '장비 포트', '채널 코드'는 자동 등록되도록 선택할 수 있습니다.",
      registerChannelLineMsgSecond:
        "2. 채널 장비의 '관리 IP', '모델명', '펌웨어' 정보는 잘못 입력되었어도 채널 업로드시 무시되는 데이터입니다.",
      lineNoNotCorrect: "'회선번호_M'과 '회선번호_B'는 다른 값이어야 합니다.",
      availableChSvcId: '사용가능한 서비스ID입니다',
      unavailableChSvcId: '사용할 수 없는 서비스ID입니다',
      emptyChSvcId: '서비스ID를 입력하세요',
      duplicateCheckChSvcId: '서비스ID 중복확인을 하세요',
      availableChNo: '사용가능한 채널번호입니다',
      unavailableChNo: '사용할 수 없는 채널번호입니다',
      emptyChNo: '채널번호를 입력하세요',
      duplicateCheckChNo: '채널번호 중복확인을 하세요',
      setEquipBefore: '장비를 먼저 선택하세요',
      emptyMainEquipment: '선택된 메인장비가 없습니다.',
      emptyEquipment: '등록된 장비가 없습니다.',

      insert: '등록 후 서비스 아이디는 변경할 수 없습니다.<br/>등록하시겠습니까?',
      delete: '해당 서비스 아이디 재사용시 모든 정보를 재 입력하셔야 합니다.<br/>정말 삭제 하시겠습니까?',
    },
    intrlkSysMgmt: {
      delete: 'NMS은 룰셋, 트랩, 장비에 연결되어 있습니다 <br/> 정말 삭제 하시겠습니까?',
    },
    equipmentMgmt: {
      duplicateHostNm: '사용할 수 없는 호스트 명입니다.',
      duplicateHostNmUI: '메인 또는 백업장비에서 사용하는 호스트명입니다.',
      duplicateHostNmBUI: '백업장비에서 사용하는 호스트명입니다.',
      duplicateOkHostNm: '사용가능한 호스트 명입니다.',
      duplicateErrorHostNm: '에러가 발생했습니다.',
      duplicateUndefinedHostNm: '호스트명을 입력해주세요',
      delete: '장비는 채널, 장비그룹, 토폴로지에 연결되어 있습니다 <br/> 정말 삭제 하시겠습니까?',
      intrlkSysSelect: 'NMS을 먼저 선택해주세요.',
      haTypeCheckTilte: '대상장비 HA유형 자동변경',
      haTypeCheckMsg: '대표장비와 멤버장비의 HA 유형이 달라, 멤버장비의 HA 유형이 변경됩니다.<br/>',
      upload: '장비 업로드',
      uploadResult: '업로드 결과',
    },
    monitoringSTBMgmt: {
      duplicateSaid: '사용할 수 없는 SAID입니다.',
      duplicateOkSaid: '사용가능한 SAID입니다.',
      duplicateErrorSaid: '에러가 발생했습니다.',
    },
    connectionMgmt: {
      delete:
        '접속정보는 RPA 템플릿,RPA 실행설정에 연결되어 있습니다 <br/> 정말 삭제 하시겠습니까?',
    },
    statisticsReporting: {
      invalidSearchDataChannelList: '채널을 1개이상 선택해 주세요.',
      MaxinvalidSearchDataChannelList: '채널은 5개이하로 선택해 주세요.',
    },
    channelDbsSync:
    {
      validAlertMsg: '채널을 선택해 주세요.',
      confirm: '선택하신 항목을 적용하시겠습니까?'
    }
  },
  systemManagement: {
    userMgmt: {
      reject: '승인 거절하시겠습니까?',
      approval: '승인 완료하시겠습니까?',
      suspend: "계정을 정지 하시겠습니까?",
      restore: "계정을 복원 하시겠습니까?",
      inactiveClear: '휴면 해제하시겠습니까?',
      availableLoginId: '사용가능한 아이디입니다',
      unavailableLoginId: '사용할 수 없는 아이디입니다',
      emptyLoginId: '로그인 ID를 입력하세요',
      duplicateCheckLoginId: '로그인 ID 중복확인을 하세요',
      pwdNotCorrect: '비밀번호와 비밀번호 확인이 다릅니다',
    },
    usrGrpMgmt: {
      availableLoginId: '사용가능한 이름입니다',
      unavailableLoginId: '사용할 수 없는 이름입니다',
      duplicateCheckLoginId: '그룹 이름을 중복확인 하세요',
      emptyLoginId: '그룹 이름을 입력하세요',
    },
    ppCompanyMgmt: {
      availablePpNm: '사용가능한 pp명입니다',
      unavailablePpNm: '사용할 수 없는 pp명입니다',
      emptyPpNm: 'pp명을 입력하세요',
      //duplicateCheckPpNm: 'pp명 중복확인을 하세요',
    },
  },
  alarmManagement: {
    snmpRuleset: {
      mibUploadTitle: 'MIB 등록',
      mibUploadError: '이미 등록되어 있는 정보들이 초기화될 수 있습니다. 진행하시겠습니까?',
      fileEmpty: '파일을 첨부하세요',
    },
    stbSimulator: {
      info: '채널정보를 갱신하시겠습니까?',
    },
  },
};

export const SNACKBAR_MSG = {
  common: {
    insert: '등록되었습니다.',
    update: '수정되었습니다.',
    delete: '삭제되었습니다.',
    save: '저장되었습니다.',
    apply: '적용되었습니다.',
    error: '에러가 발생하였습니다.'
  },
  alarmManagement: {
    test: '테스트를 완료하였습니다.',
    renewal: '갱신되었습니다.',
    reflection: '반영되었습니다.'
  },
  configurationManagement: {
    channelMgmt: {
      copy: '복사되었습니다.',
    },
  },
  systemManagement: {
    userMgmt: {
      reject: '승인 거절되었습니다.',
      approval: '승인 완료되었습니다.',
      inactiveClear: '휴면 해제되었습니다.',
      suspend: '계정이 정지 되었습니다.',
      restore: '계정이 복원 되었습니다.'
    },
  },
};

export const MENU_PAGE_TITLE = {
  // auth: {
  //   title: '사용자 X',
  //   login: '로그인',
  //   mypage: '마이페이지 X',
  // },
  dashboard: {
    title: '대시보드',
    dashboard: '대시보드',
    baekseokChannel: '백석(채널)',
    systemStatusDashboard: '수집 연동 현황',
    // yeouidoVod: '여의도(VOD)',
  },
  staticsReporting: {
    title: '통계/리포트',
    realTimeAlarm: '실시간 알람현황',
    alarmHistory: '알람내역 조회',
    allAlarmHistory: '알람전체내역 조회',
    alarmAnalyze: '메시지 분석',
    vocHistory: 'VOC 내역 조회',
    vocStatistics: 'VOC 통계',
    ratingStatistics: '시청률 통계',
    stbStatistics: 'STB 통계',
    report: '일일 점검 리포트',
    qualityReport: '품질 점검 리포트',
  },
  supplementaryService: {
    title: '부가서비스 분석',
    aQuetone: 'A큐톤',
    bQuetone: 'B큐톤',
    dQuetone: 'D큐톤',
    quetoneDpcc: '장애인 방송',
    misTransMission: '채널 소스 분석'
  },
  alarmManagement: {
    title: '알람설정',
    ruleset: 'Ruleset 설정',
    snmpRuleset: 'SNMP 설정',
    stbSimulator: 'STB 제어',
  },

  sopKdbManagement: {
    title: 'SOP',
    sopRegisterMgmt: 'SOP 관리',
    kdbMgmt: 'KDB 관리',
  },
  configurationManagement: {
    title: '형상 관리',
    channelMgmt: '채널 라인업',
    intrlkSysMgmt: 'NMS 관리',
    equipmentMgmt: '장비 등록 관리',
    // rackMgmt: 'Rack/분전반 정보 관리',
    topologyMgmt: '토폴로지 관리',
    monitoringStbMgmt: '모니터링 STB 관리',
    ppCompanyMgmt: 'PP사 관리',
    channdlDbsSync: '채널 정보 동기화'
  },
  systemManagement: {
    title: '시스템 관리',
    userMgmt: '사용자 관리',
    userLoginHistory: '사용자 로그인 이력', 
    groupMgmt: '그룹 관리',
    groupMenuMgmt: '그룹 메뉴 관리',
    pairAlarmSetting: 'Pair알람설정',
    connectionMgmt: '접속 정보 관리',
    channelCodeMgmt: '채널 코드 관리',
    apiHistory: 'API 이력 조회',
    smsEmailManagement: 'SMS/Email 전송 설정 관리',
    smsTransmission: 'SMS(장애)전송설정',
    emailTransmission: '점검리포트 전송 설정',
    notificationMgmt: '공지사항 관리',
  },
  realtimeMonitoring: {
    title: '실시간 모니터링',
    channelAlarm: '단일채널알람',
    quetoneAlarm: '큐톤알람',
    vocAlarm: 'VOC알람',
    multiChannelAlarm: '멀티채널알람',
    disabledPersonAlarm: '장애인자막알람',
  }
};

export const TABLE_HEADER = {
  systemManagement: {
    userMgmt: {
      userSid: 'ID',
      loginId: '로그인 ID',
      userNm: '이름',
      usrgrpList: '그룹',
      acntSttsCd: '계정상태',
      regDt: '등록일시',
      updDt: '수정일시',
    },
    userLoginHistory: {
      userSid: 'ID',
      loginId: '로그인 ID',
      userNm: '이름',
      usrgrpList: '그룹',
      acntSttsCd: '계정상태',
      regDt: '등록일시',
      updDt: '수정일시', 
    },
    groupMgmt: {
      usrgrpSid: '그룹 ID',
      usrgrpNm: '그룹명',
      dscrpt: '설명',
      hasRole: '보유권한',
      regDt: '등록일시',
      updDt: '수정일시',
    },
    groupMenuMgmt: {
      usrgrpSid: '그룹 ID',
      usrgrpNm: '그룹명',
    },
    apiHistorySearch: {
      recvDt: '요청된 일시',
      transacId: '트랜젝션ID',
      hostNm: '호스트명',
      method: '메소드',
      uri: 'URI',
      result: '결과',
      errCd: '에러코드',
    },
    ppCompanyMgmt: {
      ppSid: 'PP Id',
      ppNm: 'PP 명',
      ppLo: 'PP 위치',
      ppMngr: '담당자(연락처)',
      encodFwdYn: '인코더 PP사 배치',
      regDt: '등록일시',
      updDt: '수정일시',
      affiliate: '계열사',
    },
  },
  alarmManagement: {
    pairAlarmMgmt: {
      eventTypeSid: '알람 유형 ID',
      eventType: '알람 유형',
      problCause: '발생 이유',
      regDt: '등록일시',
      updDt: '수정일시',
    },
    ruleset: {
      sysNm: 'NMS명',
      equipType: '장비유형',
      ruleSetNm: '룰셋명',
      ruleSetType: '룰셋유형',
      analsType: '분석유형',
      useYn: '사용여부',
      regDt: '등록일시',
      updDt: '수정일시',
      rulesetMapping: {
        eventType: '알람 타입',
        problCas: '발생 원인',
        spcfProb: '상세 원인',
        svrt: '등급',
        andOr: '연산관리자',
        useYn: '사용여부',
        order: '순서',
        regDt: '등록일시',
        updDt: '수정일시',
      },
    },
    snmpRuleset: {
      sysNm: 'NMS명',
      trapId: '트랩ID',
      trapNm: '트랩명',
      eventType: '알람 타입',
      mibNm: 'MIB',
      entprOid: 'ENTERPRISE OID',
      useYn: '사용여부',
      regDt: '등록일시',
      updDt: '수정일시',
      svrt: '등급',
      sttusVal: '상태값',
      spcfProbOid: '상세 원인 OID',
      msgOid: '메시지 OID',
      problCas: '발생원인',
      spcfProb: '상세원인',
    },
  }, 
  configurationManagement: {
    channelMgmt: {
      chSvcId: '서비스ID',
      chNo: '채널번호',
      chNm: '채널명',
      chType: '채널 유형',
      sadInfo: '수급정보',
      hdMip:'HD M-IP',
      encodHdMip: 'Encoder HD M-IP',
      trmNetVendr: '전송망공급사',
      lineNoMain: '회선번호_M',
      lineNoBackup: '회선번호_B',
      regDt: '등록일시',
      updDt: '수정일시',
      groupChannel: '그룹 공동',
      msppNm: 'MSPP명',
      pp: {
        ppSid: 'PP ID',
        ppNm: 'PP사 명',
        ppLo: 'PP위치',
        ppMngr: '담당자(연락처)',
        encodFwdYn: '인코더 PP사 배치',
        regDt: '등록일시',
        regId: '등록자',
        updDt: '수정일시',
        updId: '수정자',
      },
      history: {
        chChgWrkUid: '작업Id',
        wrkDt: '작업시간',
        chWrkType: '작업유형',
        chWrkTypeSttus: '작접유형(상태)',
        fileNm: '파일',
        chWrkSttus: '작업상태',
        wrkrDept: '작업자부서',
        wrkrNm: '작업자',
        wrkrNmDept: '작업자(부서)',
        chSvcId: '서비스ID',
        wrkMemo: '메모',
      },
      dbsSync: {
        dbsSyncSelect: '선택',
        dedicatedChYn: '여의도 전용 채널',
        chWrkType: '작업유형',
        chSvcId: '서비스ID',
        chNo: '채널번호',
        chNm: 'DBS채널명',
        multicastIp: "멀티캐스트 IP",
        dplxYn: "양방향"
      },
    },
    connectionInfo: {
      connSid: '접속정보 ID',
      connNm: '접속정보 명',
      prtl: 'Protocol',
      ipadr: 'IP 주소',
      port: 'Port',
      intrlkSysSrvrNm: 'NMS서버명',
      equipHostNm: '장비명',
      regDt: '등록일시',
      updDt: '수정일시',
    },
    equipmentMgmt: {
      equipSid: '장비ID',
      equipType: '장비유형',
      equipRole: '장비역할',
      trtResol: '처리해상도',
      hostNm: '호스트 이름',
      modelNm: '모델명',
      venderNm: '공급사명',
      mgmtIp: '관리IP',
      regDt: '등록일시',
      updDt: '수정일시',
      frmwrVer: '펌웨어.ver',
      helthySttus: 'NMS연동상태',
      portList: {
        portSid: '포트SID',
        portNm: '포트명',
        portIp: '포트대표IP',
        idx: '포트인덱스',
        portType: '포트유형',
        portRole: '포트역할',
        portSttus: '포트상태',
        portDescr: '포트 설명',
        dstEquipNm: '연결(수신) 장비',
        dstPortNm: '연결(수신) 포트',
      },
    },
    intrlkSysMgmt: {
      intrlkSysSid: 'ID',
      sysNm: '시스템명',
      vendorNm: '공급사명',
      mnfcNm: '제조사',
      haType: 'HA 유형',
      sysType: '시스템 유형',
      regDt: '등록일시',
      updDt: '수정일시',
      intrlkSysSrvrList: {
        intrlkSysSvrSid: 'ID',
        sysNm: '서버명',
        //대기
        mngIp: '관리 IP',
        haSttus: 'HA 상태',
        helthSttus: 'NMS상태',
      },
    },
    channelCodeMgmt: {
      cdId: '코드 ID',
      cdNm: '코드명',
      dscrpt: '설명',
      useYn: '사용여부',
      updDt: '수정일시',
      codeDetailList: {
        cdId: '코드 ID',
        cdNm: '코드명',
        cdDispNmEng: '코드영문명',
        dscrpt: '설명',
        cdOrder: '순서',
        useYn: '사용여부',
      },
    },
  },
};

export const DIALOG_TITLE = {
  login: {
    otp: {
      title: "OTP 인증",
      modal: "OTP 번호입력",
    }
  },
  systemManagement: {
    userMgmt: {
      detail: '사용자 상세정보',
      insert: '사용자 등록',
      update: '사용자 수정',
    },
    groupMgmt: {
      detail: '그룹 상세보기',
      insert: '그룹 등록',
      update: '그룹 수정',
    },
  },
  alarmManagement: {
    pairAlarmMgmt: {
      detail: 'Pair 알람 상세정보',
      update: 'Pair 알람 수정',
      insert: 'Pair 알람 등록',
    },
    ruleset: {
      detail: 'RuleSet 상세정보',
      textDetail: 'Text RuleSet 상세정보',
      update: 'RuleSet 수정',
      insert: 'RuleSet 등록',
      mapping: {
        detail: 'RuleSet Mapping 상세정보',
        update: 'RuleSet Mapping 수정',
        insert: 'RuleSet Mapping 등록',
      },
    },
    snmpRuleset: {
      detail: 'SNMP 상세정보',
      update: 'SNMP 수정',
      mibUpload: 'MIB 등록',
    },
  },
  sopKdbManagement: {
    kdbMgmt: {
      detail: 'KDB 상세정보',
      insert: 'KDB 등록',
      update: 'KDB 수정',
    },
    sopRegisterMgmt: {
      detail: 'SOP 상세정보',
      insert: 'SOP 등록',
      update: 'SOP 수정',
    },
  },
  configurationManagement: {
    channelMgmt: {
      detail: '채널 상세정보',
      insert: '채널 등록',
      update: '채널 수정',
      channelLineRegister: '채널 라인업 등록',
      ppSelect: 'PP사 선택/등록',
    },
    equipmentMgmt: {
      detail: '장비 상세정보',
      insert: '장비 등록',
      update: '장비 수정',
      equipmentSelect: '장비 선택',
      upload: '장비 업로드',
      uploadTitle: '장비 일괄 업로드',
      fileUpload: '파일 업로드',
    },
    intrlkSysMgmt: {
      detail: 'NMS 상세정보',
      insert: 'NMS 등록',
      update: 'NMS 수정',
    },
    connectionMgmt: {
      detail: '접속 정보 상세정보',
      insert: '접속 정보 등록',
      update: '접속 정보 수정',
    },
    monitoringSTBMgmt: {
      detail: '모니터링 STB 상세정보',
      insert: '모니터링 STB 등록',
      update: '모니터링 STB 수정',
    },
    channelCodeMgmt: {
      detail: '채널 코드 상세보기',
      update: '채널 코드 수정',
    },
    ppCompanyMgmt: {
      detail: 'PP 상세정보',
      insert: 'PP 등록',
      update: 'PP 수정',
    },
  },
  topologyMgmt: {
    insert: '장비 그룹 등록',
    update: '장비 그룹 수정',
    detail: '장비 그룹 선택',
  },
};

export const FORMSET_TITLE = {
  systemManagement: {
    userMgmt: {
      basicInfo: '기본정보',
    },
    groupMgmt: {
      basicInfo: '기본정보',
    },
    apiHistorySearch: {
      basicInfo: '기본정보',
    },
    ppCompanyMgmt: {
      basicInfo: '기본정보',
    },
  },
  alarmManagement: {
    pairAlarmMgmt: {
      basicInfo: '기본정보',
      eventType: '알람 유형',
    },
    ruleset: {
      basicInfo: '기본정보',
      textMappingList: 'TEXT 매핑 리스트',
      ruleSetMappingInfo: 'RuleSet 매핑 정보',
      MappingCondition: '매핑 조건',
    },
    snmpRuleset: {
      basicInfo: '기본정보',
      settingInfo: '설정 정보',
    },
    stbSimulator: {
      basicInfo: '기본정보',
    }
  },
  sopKdbManagement:{
    kdbMgmt:{
      basicInfo: '기본정보',
      kdb: 'KDB',
    },
    sopRegisterMgmt: {
      sop: 'SOP정보',
      basicInfo: '기본정보',
    },
  },
  statisticsReporting: {
    alarmHistory: {
      basicInfo: '기본정보',
    },
    voc: {
      basicInfo: '기본정보',
    },
  },
  configurationManagement: {
    channelMgmt: {
      equipInfo: '장비 정보',
      fileUpload: '파일 선택',
      basicInfo: {
        title: '기본정보',
        advertisementInfo: '광고 정보',
        multicastInfo: '고객 Multicast 정보',
        tsInfo: 'TS 송출 정보',
        otherInfo: '부가서비스 정보',
      },
      ppLeasedLineInfo: {
        title: 'PP사 / 전용회선 정보',
        pp: 'PP사',
        leasedLine: '전용회선',
      },
      sdSwitch: 'SD SWITCH',
      transcoder: 'TRANSCODER',
      encoder: {
        title: 'Encoder',
        advertisementInfo: '광고 정보',
        tsInfo: 'TS 정보',
      },
      inPort: 'In Port',
      outPort: 'Out Port',
      inBPort: 'In Port(백업)',
      outBPort: 'Out Port(백업)',
      inputSwitch: 'INPUT SWITCH',
      ipAco: {
        title: 'IP-ACO',
        multicastIp: 'Multicast IP',
        ara: 'ARA',
        kt: 'KT',
      },
      mux: {
        title: 'MUX',
        hdMux: 'HD',
        sdMux: 'SD',
      },
      outputSwitch: 'OUTPUT SWITCH',
    },
    connectionMgmt: {
      basicInfo: '기본 정보',
    },
    equipmentMgmt: {
      basicInfo: '기본 정보',
      portInfo: '포트 정보',
      equipmentInfo: '장비 정보',
      equipmentSelect: '장비 선택',
    },
    intrlkSysMgmt: {
      basicInfo: '기본 정보',
      intrlkSysInfo: 'NMS 정보',
      intrlkSysSvrInfo: '서버 정보',
      intrlkSysSelect: 'NMS 선택',
      intrlkSysSvrSelect: '서버 선택',
    },
    monitoringSTBMgmt: {
      basicInfo: '기본 정보',
    },
    channelCodeMgmt: {
      basicInfo: '기본 정보',
      codeList: '코드 항목',
    },
    topologyMgmt: {
      basicInfo: '기본 정보',
      selectEquipment: '장비 선택',
    },
  },
};

export const FORMSET_ITEM_LABEL = {
  common: {
    reg: '등록',
    upd: '수정',
    attachment: '첨부 파일',
  },
  systemManagement: {
    userMgmt: {
      userSid: 'ID',
      loginId: '로그인 ID',
      userNm: '이름',
      usrgrpSidList: '그룹',
      roleList: '권한 목록',
      acntSttsCd: '계정상태',
      pwd: '비밀번호',
      pwdCorrect: '비밀번호 확인',
      regSdt: '등록일시(시작)',
      regEdt: '등록일시(끝)',
      regUser: '등록자',
      updUser: '수정자',
      regDt: '등록일시',
      updDt: '수정일시',
      sessn: '세션유지여부',
      lastLoginDt: '최근 로그인',
    },
    userLoginHistory: {
    
      loginId: '로그인 ID',
      userNm: '사용자명',
      loginReqTime: '로그인시간',
      clntAddr: '접속 IP',
      sucesYn: '성공여부',
      authDtSt: '시간(시작)',
      authDtEd: '시간(끝)',
      sucesYnAll: '전체',
      sucesYnTrue: '성공',
      sucesYnFalse: '실패',
      
    },
    myPage: {
      basic: '마이페이지',
      myInfo: '내 정보',
    },
    groupMgmt: {
      usrgrpSid: '그룹 ID',
      usrgrpNm: '그룹명',
      dscrpt: '설명',
      hasRole: '보유권한',
      regUser: '등록자',
      updUser: '수정자',
      regDt: '등록일시',
      updDt: '수정일시',
    },
    groupMenuMgmt: {
      usrgrpSid: '그룹 ID',
      usrgrpNm: '그룹명',
    },
    apiHistorySearch: {
      recvDt: '요청된 일시',
      transacId: '트랜젝션ID',
      hostNm: '호스트명',
      clientAdress: '클라이언트 주소',
      method: '메소드',
      uri: 'URI',
      requsetBody: 'Request Body',
      result: '결과',
      httpStatus: 'HTTP STATUS',
      errCd: '에러코드',
      errMsg: '에러메세지',
      responseContentLength: 'Response 컨텐츠 길이',
      takeTime: '소요시간',
    },
    ppCompanyMgmt: {
      ppSid: 'PP ID',
      ppNm: 'PP 명',
      ppLo: 'PP 위치',
      ppMngr: '현장담당자(연락처)',
      encodFwdYn: '인코더 PP사 배치',
      ppMemo: 'PP 메모',
      regUser: '등록자',
      updUser: '수정자',
      regDt: '등록일시',
      updDt: '수정일시',
      affiliate: '계열사',
      monitorCircuitNoM: '감시회선번호(M)',
      monitorCircuitNoB: '감시회선번호(B)', 
    },
    notificationMgmt: {
      ntfSid: '공지 ID',
      ntfTitle: '제목',
      ntfConts: '내용',
      ntfOrder: '순서',
      showDt: '노출 기간',
      showStartDt: '노출 시작일',
      showEndDt: '노출 종료일',


      regDt: '등록일시',
      updDt: '수정일시',
      regDtSt: '시작일',
      regDtEd: '종료일',
      isNotice: '공지중',
    }
  },
  statisticsReporting: {
    voc: {
      svcId: '서비스ID',
      stsCode: '상태',
      regDtSt: '조회기간 시작',
      regDtEd: '조회기간 종료',
      vocSeq: 'VOC 순번',
      receiptDt: '접수 일시',
      svcMainTypeCode: 'VOC레벨1유형',
      svcSubTypeCode: 'VOC레벨2유형',
      svcSubSubTypeCode: 'VOC레벨3유형',
      svcSubSubSubTypeCode: 'VOC레벨4유형',
      causeDeptDstInCode: '유발부서명',
      prmVocChargeDeptCode: '책임부서명',
      custReqsInfo: '접수내용',
      prodSubCode: 'VOC 상품명',
      sidoCode: '지역',
    },
    stbStatistics: {
      data: '데이터',
      region: '지역',
      model:'단말기종',
      statType: '집계 유형',
      statUnit: '집계 단위',
      statDtSt: '시작일',
      statDtEd: '종료일',
      withPre: '4주 평균',

      paketloss: '품질',
      alarm: '장애알람',
      byModel: '단말기종단위',
      byRegion: '지역단위',
    },
    report: {
      chkDtSt: '시작일',
      chkDtEd: '종료일',
      chkDt: '점검일',
    },
    ratingStatistics: {
      data: '데이터',
      region: '지역',
      channel: '채널',
      statType: '집계 유형',
      statUnit:'집계 단위',
      statDtSt: '시작일',
      statDtEd: '종료일',
      withPre: '4주 평균',

      ratings: '시청률',
      occupy: '점유율',
      views: '시청가구',
      byRegion: '지역단위',
      byChannel: '채널단위',
    },
    vocStatistics: {
      data: '데이터',
      region: '지역',
      statType: '유형',
      statUnit:'집계 단위',
      statDtSt: '시작일',
      statDtEd: '종료일',
      withPre: '4주 평균',
    },
    alarmHistory: {
      eventId: '알람ID',
      intrlkSysSid: 'NMS ID',
      instanceType: '인스턴스 유형',
      instanceName: '인스턴스 이름',
      targetInstanceName: '장비명',
      instanceId: '인스턴스 ID',
      portId: '포트 ID',
      portName: '포트 이름',
      instType2: '인스턴스 유형Z',
      instNm2: '인스턴스 이름Z',
      instId2: '인스턴스 IDZ',
      portId2: '포트 IDZ',
      portNm2: '포트 이름 IDZ',
      eventTrgtType: '알람 타겟 유형',
      eventTrgtSid: '알람 타겟 ID',
      eventTrgtNm: '알람 타겟 이름',
      afectCh: '영향 채널',
      chNm: '채널명',
      eventType: '알람 유형',
      eventState: '알람 상태',
      probableCause: '발생 원인',
      specificProblem: '상세 원인',
      perceivedSeverity: '등급',
      originSvrt: '기존 등급',
      eventGroupType: '알람 그룹 유형',
      eventCretMagent: '알람 생성 주체',
      prntEventYn: '부모 알람 유무',
      childEventYn: '자식 알람 유무',
      eventTime: '알람 시간',
      cleared: '해지 여부',
      acknowledgedTime: '인지 시간',
      clearanceTime: '해지 시간',
      trmnCause: '해지 원인',
      additionalInformation: '추가 INFO',
      additionalText: '추가 TEXT',
      operatorNote: '운영 NOTE',
      externalEventId: '외부알람 ID',
      equipType: '장비 유형',
      equipTypeSearch: '장비 구분',
      statDtSt: '시작일',
      statDtEd: '종료일',
      QuetoneAndDp: '알람구분'

    },
  },
realtimeMonitoring: {
  eventState: '상테',
  outstanding: '발생',
  acknowledged: '인지',
  terminated: '종료',
  perceivedSeverity: '등급',
  critical: 'CR',
  major: 'MA',
  minor: 'MI',
  warning: 'WA',
  targetInstanceId: '채널SID',
  targetInstanceName: '채널명',
  specificProblem: '상세원인',
  message: '메시지',
  eventTimeSt:'발생시각',
  eventTimeEd: '종료시각',
  rca: '추정RCA',
  mvTime: 'MV발생시각'

},

  supplementaryService: {
    quetone: {
      quetoneDtSt: '날짜',
      channel: '채널',
    },
  },
  alarmManagement: {
    pairAlarmMgmt: {
      occEventTypeSid: '발생ID',
      occEventType: '발생 알람 타입',
      occProblCause: '발생원인',
      occSpcfProbl: '발생상세원인',
      trmnEventTypeSid: '해지ID',
      trmnEventType: '해지알람 타입',
      trmnProblCause: '해지원인',
      trmnSpcfProbl: '해지상세원인',
      descr: '설명',
      regDt: '등록일시',
      regUser: '등록자',
      updUser: '수정자',
      updDt: '수정일시',
      eventType: {
        eventTypeSid: '알람 유형 ID',
        eventType: '알람 유형',
        problCause: '발생 이유',
      },
    },
    ruleset: {
      sysNm: 'NMS명',
      equipType: '장비 유형',
      ruleSetNm: '룰셋명',
      ruleSetType: '룰셋유형',
      useYn: '사용여부',
      regDt: '등록일시',
      regUser: '등록자',
      updUser: '수정자',
      updDt: '수정일시',
      test: '테스트',
      analsType: '분석유형',
      abstactClass: '추출클래스',
      ananlsRule: '분석 룰',

      rulesetMapping: {
        eventType: '알람 타입',
        problCas: '발생 원인',
        spcfProb: '상세 원인',
        andOr: '연산관리자',
        svrt: '등급',
        useYn: '사용여부',
        atnInfo: '추가 정보',
        regDt: '등록일시',
        regUser: '등록자',
        updUser: '수정자',
        updDt: '수정일시',
        regSdt: '등록일시(시작)',
        regEdt: '등록일시(끝)',
        ruleSetMappingCondList: {
          extrtKey: '추출키',
          typeCond: '조건',
          comprVal: '비교값',
        },
      },
      intrlkSys: 'NMS',
      regSdt: '등록일 시작',
      regEdt: '등록일 종료',
      updSdt: '수정일 시작',
      updEdt: '수정일 종료',
    },
    snmpRuleset: {
      sysNm: 'NMS명',
      trapId: '트랩 ID',
      trapNm: '트랩명',
      entprOid: 'ENTERPRISE OID',
      mibNm: 'MIB',
      apdInfo: '추가정보',
      regDt: '등록일시',
      updDt: '수정일시',
      regUser: '등록자',
      updUser: '수정자',
      useYn: '사용여부',
      eventType: '알람 타입',
      svrt: '등급',
      problCas: '발생 원인',
      sttusOid: '상태 OID', //트랩변수목록
      sttusVal: '상태값', //변수상태목록
      spcfProb: '상세 원인',
      spcfProbVarOidList: '상세 원인 OID',
      intrlkSys: 'NMS',
      fileList: 'MIB 파일',
      regSdt: '등록일시(시작)',
      regEdt: '등록일시(끝)',
      msg: '메시지',
      msgOid: '메시지 OID'
    },
    stbSimulator: {
      ctg: '분류',
      lo: '위치',
      helthySttus: '현재상태',
    },
  },
  sopKdbManagement: {
    kdbMgmt: {
      kdbSid: 'KDB ID',
      instType: '인스턴스 유형',
      eventType: '알람 유형',
      problCause: '발생원인',
      spcfProbl: '상세원인',
      trtSchm: '처리내역',
      delYn: '삭제여부',
      regDt: '등록일시',
      updDt: '수정일시',
      regUser: '등록자',
      updUser: '수정자',
    },
    sopRegisterMgmt: {
      sopSid: 'SOP ID',
      sopNm: 'SOP 명',
      equipType: '장비 유형',
      sopUnderType: 'SOP 하위 유형',
      sysNm: '시스템명',
      trtSchm: '처리 방안',
      tkcgr: '담당자',
      delYn: '삭제 여부',
      tbSopEventTypeRel: {
        title: '알람 유형 연관 정보',
        instType: '인스턴스 유형',
        eventType: '알람 유형',
        problCause: '발생 원인',
        spcfProbl: '상세 원인',
      },
      sopFile: {
        sopSid: 'SOP ID',
        fileOrdr: '파일 순서',
        filePath: '파일 경로',
        fileName: '파일 명',
        sopFile: 'SOP 파일',
      },
      regDt: '등록일시',
      updDt: '수정일시',
      regUser: '등록자',
      updUser: '수정자',
    },
  },
  configurationManagement: {
    channelMgmt: {
      //채널 선번장
      progrCnt: '진행 수',
      allChCnt: '총 채널 수',
      newChCnt: '신규 채널 수',
      uNumberChCnt: '수정 채널 수',
      delYneteChCnt: '삭제 채널 수',
      lnsFile: '채널 라인업',
      //기본정보
      chSvcId: '서비스ID',
      chNo: '채널번호',
      chType: '채널 유형',
      chNm: '채널명',
      dbsChNm: 'DBS채널명',
      trtResol: '해상도',
      sadInfo: '수급정보',
      groupChannel: '그룹 공동',
      skylife: 'SkyLife',
      hcn: 'HCN',
      seezn: 'Seezn',
      rglsSttus: '상용상태',
      rglsComotDate: '상용출시일',
      adultYn: '성인채널',
      //광고정보
      araIntrlkYn: 'ARA연동',
      advmSpatn: '광고 분리',
      advmMemo: '광고 메모',
      qtoneAPid: 'A Que PID',
      qtoneBPid: 'B Que PID',
      qtoneDPid: 'D Que PID',
      //고객 Multicast 정보
      hdMip: 'HD M-IP',
      sdMip: 'SD M-IP',
      //TS 정보
      psiIn: 'PSI IN',
      pat: 'PAT',
      pmt: 'PMT',
      videoPid: 'V PID',
      audio1Pid: 'A PID1',
      audio2Pid: 'A PID2',
      webAit: 'WEB AIT',
      acapAit: 'ACAP AIT',
      webOc: 'WEB OC',
      acapOc: 'ACAP OC',
      //부가서비스 정보
      videoStchYn: '비디오스티칭',
      vcMultiYn: '화면해설/음성다중',
      dpsonSubtitYn: '장애인 자막',
      dplxYn: '양방향',
      casEcodYn: 'CAS 암호화',
      ecmPid: 'ECM PID',
      //PP사 / 전용회선 정보
      ppNm: 'PP사 명',
      ppMngr: '담당자(연락처)',
      ppMemo: 'PP 메모',
      ktCircuitNo: '회선번호',
      //전용회선
      msppNm: 'MSPP명',
      msppPort: 'MSPP포트',
      msppBNm: 'MSPP명(백업)',
      msppBPort: 'MSPP포트(백업)',
      niNm: 'NI',
      // ni: 'NI',
      lineSpatn: '회선 분리',
      ktTrsmDept: 'KT 구간',
      trmNetVendr: '전송망공급사',
      lineNoMain: '회선번호_M',
      lineNoBackup: '회선번호_B',
      lineNoSpare: '회선번호_예비',
      //인코더
      encodHdMip: 'HD M-IP(입수,메인)',
      encodSdMip: 'SD M-IP',
      encodCcYn: 'Close Caption',
      encodPmtPid: 'PMT',
      encodVideoPid: 'V PID',
      encodAudioPid: 'A PID1',
      encodAudio2Pid: 'A PID2',
      no: '인코더 NUM.',
      sorcIp30: 'Source IP/30',
      gw: 'Source G/W',
      kt: 'kt',
      //IP ACO
      ktInPort: 'IN PORT(입수L3)',
      ktBInPort: 'IN PORT(입수L3-백업)',
      lgInPort: 'IN PORT(LG-DR)',
      psiInPort: 'IN PORT(PSI)',
      skyOutPort: 'OUT PORT(Sky)',
      skyAraInPort: 'IN PORT(Sky Ara)',
      seeznOutPort: 'OUT PORT(Seezn)',
      muxOutPort: 'OUT PORT',
      muxBOutPort: 'OUT PORT(백업)',
      ktInMip: 'kt IN M-IP',
      outMip: 'OUT M-IP',
      skyInMip: 'Sky IN M-IP',
      lgInMip: 'LG-DR IN M-IP',
      //EqiupTabContent
      outPort: 'OUT PORT',
      emgOutPort: 'EMG OUT PORT',
      hostNm: '장비',
      mgmtIp: '관리 IP',
      inPort: 'IN PORT',
      outPortHd: 'OUT PORT(IP ACO-메인)',
      outBPortHd: 'OUT PORT(IP ACO-백업)',
      emgIn: 'EMG IN(메인)', //OUT PORT
      inBPort: 'IN PORT(백업)',
      emgOut: 'EMG OUT(백업)',//OUT PORT(백업)
      scsPort: 'SCS PORT', //SCS PORT
      ipout: 'IPOUT',
      main: 'Main',
      backup: 'Backup',
      copyMainToBackup: '입력값 복사(M->B)',
      modelNm: '모델명',
      firmware: '펌웨어',
      psiPid: 'PSI PID',
      patPid: 'PAT PID',
      //
      regUser: '등록자',
      updUser: '수정자',
      equipMngr: '담당자(연락처)',
      equipLo: '장비위치',
      dedicatedChYn : '여의도 전용 채널',
      dedicatedChYnInclude : '여의도 전용 채널 포함',
      pp: {
        ppSid: 'PP ID',
        ppNm: 'PP사 명',
        ppLo: 'PP사 주소',
        ppMngr: '담당자(연락처)',
        encodFwdYn: '인코더 PP사 배치',
      },
      history: {
        chWrkType: '작업유형',
        exeSttus: '작업상태',
        wrkrNm: '작업자',
        chSvcId: '서비스 ID',
        wrkDtSt: '작업시간(시작)',
        wrkDtEn: '작업시간(종료)',
      },
      channelhistory: {
        chSvcId: '서비스ID',
        chNo: '채널번호',
        chNm: '채널명',
        chType: '채널 유형',
        duplSglType: '중복/단독	',
        seeznIntrlkYn: 'Seezn',
        sadInfo: '수급정보',
        adultYn: '성인채널',
        psiIn: 'PSI IN',
        advmYn: '광고',
        advmType: '광고 유형',
        qtoneANo: 'A Que Num',
        qtoneBNo: 'B Que Num',
        qtoneDPid: 'D Que Num',
        hdMip: 'HD M-IP',
        sdMip: 'SD M-IP',
        pmt: 'PMT',
        videoPid: 'V PID',
        audio1Pid: 'A PID1',
        audio2Pid: 'A PID2',
        webAit: 'WEB AIT',
        acapAit: 'ACAP AIT',
        webOc: 'WEB OC',
        acapOc: 'ACAP OC',
        videoStchYn: '비디오스티칭',
        ecodYn: '암호화',
        dpsonSubtitYn: '장애인 방송',
        dplxYn: '양방향',
        vcMultiYn: '화면해설/음성다중',
        applYn: '어플',
        helthySttus: '채널 상태',
        //전용회선 정보
        trmNetVendr: '전송망공급사',
        lineNoMain: '회선번호_M',
        lineNoBackup: '회선번호_B',
        ktTrsmDept: 'KT 구간',
        ppNm: 'PP사',
        msppNm: 'MSPP명',
        msppPort: 'MSPP포트',
        msppBNm: 'MSPP명(백업)',
        msppBPort: 'MSPP포트(백업)',
        //위성 채널 정보
        satelChNo: '채널번호',
        satelDuplSgl: '중복/단독',
        satelAdvm: '광고',
        satelAdvmType: '광고 유형',
        trsmnSuplr: '전송망',
        satelArbitMachn: '중계기',
        preSatelDegr: '위성',
        preIptvDegr: 'IPTV',
        //SD SWITCH
        sdSwEquipNm: '장비',
        sdSwMgmtIp: '관리 IP',
        sdSwInPortSid: 'IN PORT',
        sdSwOutPortSid: 'OUT PORT',
        sdSwInBPortSid: 'IN PORT(백업)',
        sdSwOutBPortSid: 'OUT PORT(백업)',
        //TRANSCODER
        trcodEquipNm: '장비',
        trcodMgmtIp: '관리 IP',
        trcodInPortSid: 'IN PORT',
        trcodOutPortSid: 'OUT PORT',
        trcodInBPortSid: 'IN PORT(백업)',
        trcodOutBPortSid: 'OUT PORT(백업)',
        //Encoder
        encodEquipNm: '장비',
        encodMainMgmtIp: '관리 IP Main',
        encodBackupMgmtIp: '관리 IP Backup',
        encodNo: '인코더 Num.',
        encodCcYn: 'Close Caption',
        encodHdMip: 'HD M-IP',
        encodSdMip: 'SD M-IP',
        encodSorcIp30: 'Source IP/30',
        encodGw: 'Source G/W',
        encodQtoneAId: 'A Que Num',
        encodQtoneBId: 'B Que Num',
        encodQtoneDPid: 'D Que PID',
        encodPmtPid: 'PMT',
        encodVideoPid: 'V PID',
        encodAudioPid: 'A PID1',
        encodAudio2Pid: 'A PID2',
        //INPUT SWITCH
        inputSwEquipNm: '장비',
        inputSwMgmtIp: '관리 IP',
        inputSwInPortSid: 'IN PORT',
        inputSwOutPortSid: 'OUT PORT(HD)',
        inputSwOutBPortSid: 'OUT PORT(HD-백업)',
        inputSwEmgOutPortSid: 'EMG OUT PORT',
        //IP ACO
        acoEquipNm: '장비',
        acoEquipMgmtIp: '관리 IP',
        acoInPortSid: 'IN PORT(입수L3)',
        acoInBPortSid: 'IN PORT(입수L3-백업)',
        acoOutPortSid: 'OUT PORT(MUX)',
        acoOutBPortSid: 'OUT PORT(MUX-백업)',
        acoSkyOutPortSid: 'OUT PORT(SKY)',
        acoYeouidoOutPortSid: 'OUT PORT(여의도)',
        acoKtInMip: 'KT IN M-IP',
        acoLgInMip: 'LG IN M-IP',
        acoSkyInMip: 'Sky IN M-IP',
        acoOutMip: 'OUT M-IP',
        //HD MUX
        hdMuxEquipNm: '장비',
        hdMuxMgmtIp: '관리 IP',
        hdMuxInPortSid: 'IN PORT',
        hdMuxOutPortSid: 'OUT PORT',
        hdMuxInBPortSid: 'IN PORT(백업)',
        hdMuxOutBPortSid: 'OUT PORT(백업)',
        hdMuxEmgInPortSid: 'EMG IN PORT',
        hdMuxMuxScs: 'MUX SCS',
        hdMuxMuxHecSwPort: 'HEC SW PORT',
        hdMuxIpout: 'IPOUT',
        //SD MUX
        sdMuxEquipNm: '장비',
        sdMuxMgmtIp: '관리 IP',
        sdMuxInPortSid: 'IN PORT',
        sdMuxOutPortSid: 'OUT PORT',
        sdMuxInBPortSid: 'IN PORT(백업)',
        sdMuxOutBPortSid: 'OUT PORT(백업)',
        sdMuxEmgInPortSid: 'EMG IN PORT',
        sdMuxMuxScs: 'MUX SCS',
        sdMuxHecSwPort: 'HEC SW PORT',
        sdMuxIpout: 'IPOUT',
        //OUTPUT SWITCH
        outputSwEquipNm: '장비',
        outputSwMgmtIp: '관리IP',
        outputSwInPortSid: 'IN PORT',
        outputSwOutPortSid: 'OUT PORT',
      },
    },
    equipmentMgmt: {
      equipSid: '장비 ID',
      hostNm: '호스트이름',
      modelNm: '모델명',
      equipType: '장비유형',
      equipRole: '장비역할',
      trtResol: '처리해상도',
      mnfc:'제조사',
      mnfcSid: '제조사ID',
      mnfcNm: '제조사명',
      vendor: '공급사명',
      vendorSid: '공급사ID',
      venderNm: '공급사명',
      buyDate: '구입일',
      installDate: '설치일',
      mgmtIp: '관리IP',
      frmwrVer: '펌웨어.ver',
      modulInclYn: '모듈포함여부',
      sysType: '시스템 유형',
      haType: 'HA유형',
      haSttus: 'HA상태',
      repYn: 'Main',
      equipOprtSttus: '장비운영',
      helthySttus: 'NMS연동상태',
      pairEquipSid: 'Main 호스트명',
      etnlEquipId: '외부장비ID',
      intrlkSys: 'NMS',
      intrlkSysSid: 'NMS ID',
      intrlkSysNm: 'NMS명',
      intrlkSysSrvr: 'NMS 서버',
      intrlkSysSrvrSid: 'NMS서버SID',
      intrlkSysSrvrNm: 'NMS서버명',
      regDt: '등록일시',
      updDt: '수정일시',
      regUser: '등록자',
      updUser: '수정자',
      equipMngr: '담당자(연락처)',
      equipLo: '장비위치',
      upTime: '부팅 후 경과시간',
      startTime: '부팅 시작일자',
      rackInfo: '랙명',
      cbtpaNmM: '분전반(M)',
      cbtpaNmB: '분전반(B)',
      slotNo: 'Slot 번호',
      portList: {
        portSid: '포트SID',
        portNm: '포트명',
        portIp: '포트대표IP',
        idx: '포트인덱스',
        portType: '포트유형',
        portRole: '포트역할',
        portSttus: '포트상태',
        portDescr: '포트 설명',
        dstPort: '연결(수신) 포트',
        dstEquip: '연결(수신) 장비',
      },
      equipCtg: '장비구분',
    },
    connectionInfo: {
      connSid: '접속정보 ID',
      connNm: '접속정보 명',
      prtl: 'Protocol',
      urlPfix: 'Url Prefix',
      equipOrSrvrNm: '장비 또는 서버 명',
      ipadr: 'IP 주소',
      port: 'Port',
      authMethod: '인증 메소드',
      Id: 'ID',
      Pwd: 'Password',
      psphrs: 'PassPhrase',
      prvtKey: '비공개 키',
      fingerprint: null,
      cmnty: 'Community',
      intrlkSysSrvrNm: 'NMS서버명',
      intrlkSysSrvrSid: 'NMS ID',
      equipHostNm: '장비명',
      equipSid: '장비 SID',
      authprtl: 'AuthProtocol',
      authPwd: 'AuthPassword',
      privacyProtocol: 'PrivacyProtocol',
      privacyPwd: 'PrivacyPassword',
      userName: 'UserName',
      delYn: '삭제 여부',
      regDt: '등록일시',
      updDt: '수정일시',
      regUser: '등록자',
      updUser: '수정자',
      regSdt: '등록일시(시작)',
      regEdt: '등록일시(끝)',
    },
    inrtlkSysMgmt: {
      intrlkSysSid: 'NMS ID',
      sysNm: '시스템명',
      mnfcSid: '제조사 ID',
      mnfcNm: '제조사 이름',
      vendorSid: '공급사 ID',
      vendorNm: '공급사 이름',
      haType: 'HA유형',
      vip: '관리 IP',
      mgmtEquipType: '관리장비유형',
      regDt: '등록일시',
      updDt: '수정일시',
      regUser: '등록자',
      updUser: '수정자',
      sysMngr: '담당자',
      intrlkSysSrvrList: {
        intrlkSysSrvrSid: 'NMS 서버 ID',
        intrlkSysSid: 'NMS ID',
        sysNm: '서버명',
        haSttus: 'HA상태',
        mngIp: '관리 IP',
        helthSttus: '서버상태',
        regDt: '등록일시',
        regUser: '등록자',
        updUser: '수정자',
        updDt: '수정일시',
      },
    },
    monitoringSTBMgmt: {
      said: 'SAID',
      stbMo: 'STB명',
      ctg: '분류',
      lo: '위치',
      nttp: '망유형',
      ip: 'IP',
      mac: 'MAC주소',
      helthySttus: 'STB상태',
      opnDt: '개통일시',
      regDt: '등록일시',
      regUser: '등록자',
      updUser: '수정자',
      updDt: '수정일시',
      rmark: '비고',
      eventZapYn: '재핑 설정',
      eventZapYnAll: '전체',
      eventZapYnTrue: '설정',
      eventZapYnFalse: '미설정', 
    },
    channelCodeMgmt: {
      cdId: '코드 ID',
      cdNm: '코드명',
      cdDispNmEng: '코드영문명',
      dscrpt: '설명',
      cdOrder: '순서',
      useYn: '사용여부',
      regUser: '등록자',
      updUser: '수정자',
      regDt: '등록일시',
      updDt: '수정일시',
    },
    topologyMgmgt: {
      equipGroupNm: '장비 그룹명',
      equipType: '장비 유형',
      description: '설명',
    },
  },
};

export enum DIALOG_TYPE {
  INSERT = 'insert',
  UPDATE = 'update',
  DETAIL = 'detail',
}

export enum RESULT_CODE {
  SUCCESS = 'S',
  FAIL = 'F',
}

export enum BUTTON_LABEL {
  CLOSE = '닫기',
  ABLE_LOGIN = '로그인방지해제',
  WAKEUP = '휴면해제',
  REFUSAL = '승인거절',
  APPROVED = '승인완료',
  DELETE = '삭제',
  INSERT = '등록',
  UPDATE = '수정',
  CONFIRM = '확인',
  SAVE = '저장',
  CANCLE = '취소',
  SEARCH = '검색',
  ADD = '추가',
  EXPORT_PDF = 'PDF 내보내기',
  TEST = '테스트',
  ACK = '인지',
  PURGE = 'Purge',
  TERMINATE = '종료',
  CLEAR = '해제',
  APPLY = '적용',
  ATTACH_FILE = '파일 첨부',
  INSPECTION_HISTORY = '점검이력',
  ALARM_DETAILS = '알람 상세보기',
  CHANGE_PASSWORD = '비밀번호 변경',
  ID_DUPLICATE_CHECK = 'ID 중복확인',
  GRP_DUPLICATE_CHECK = '그룹 이름 중복확인',
  DUPLICATE_CHECK = '중복 확인',
  CHANNEL_LINE_REGISTER = '채널 라인업 등록',
  CHANNEL_LINE_TEMPLATE_DOWNLOAD = '템플릿 다운로드',
  CHANNEL_LINE_DOWNLOAD = '채널 라인업 다운로드',
  CHANNEL_DBS_SYNC = '채널 정보 동기화(DBS)',
  CHANNEL_HISTORY = '채널 변경이력',
  CHANNEL_CODE = '코드 관리',
  CHANNEL_COPY = '채널정보 복사',
  LOGIN = 'Login',
  KDB = 'KDB',
  EVENT_SELECT = '알람 선택',
  EVENT_DETAIL = '알람 선택',
  HOSTNM_DUPLICATE_CHECK = '호스트명 중복확인',
  RPA_TEMPLATE_DETAILS = 'RPA 템플릿 상세보기',
  RPA_TEMPLATE_SELECT = 'RPA 템플릿 선택',
  EQUIPMENT_DETAILS = '장비 상세보기',
  EQUIPMENT_SELECT = '장비 선택',
  EQUIPMENT_MAIN_SELECT = '대표장비 선택',
  EQUIPMENT_PAIRINSERT = '장비 생성',
  EQUIPMENT_PAIRUPDATE = '대상장비 수정',
  SERVER_DETAIL = '서버 상세보기',
  SERVER_SELECT = '서버 선택',
  INTERACTIVESYS_DETAIL = 'NMS 상세보기',
  INTERACTIVESYS_SELECT = 'NMS 선택',
  PP_SELECT = 'PP사 선택',
  SAID_DUPLICATE_CHECK = 'SAID 중복확인',
  ALARM_SELECT = '알람 선택',
  CONNECTION_DETAILS = 'Connection 상세보기',
  RUNNING_CONSOLE_CONFIRM = '실행 콘솔 확인',
  STOP_RUNNING = '실행 중지',
  RE_RUNNING = '재실행',
  STOP_TEST = '테스트 중지',
  TRANSFER_HISTORY = '절체이력',
  COPY = '복사',
  MIB_UPLOAD = 'MIB 등록',
  COPY_M_TO_B = '입력값 복사(M->B)',
  INIT_DATA_SAVE = '초기 데이터 저장',
  EQUIP_GROUP_INSERT = '장비 그룹 등록',
  EQUIP_GROUP_UPDATE = '장비 그룹 수정',
  CHANNEL_UPDATE = '채널정보 갱신',
  DOWNLOAD = '다운로드',
  EQUIP_TEMPLATE_DOWNLOAD = '장비템플릿 다운로드',
  EQUIP_UPLOAD = '장비업로드',
  EQUIP_DOWNLOAD = '장비다운로드',
  RE_SEND = '재전송',
  PHONE_NUMBER = '전화번호 추가',
  SUSPEND = '사용 중지',
  RESTORE = '사용',
  TOGGLE_SEARCH_AREA = 'Search Area',
  TOGGLE_ALARM_MUTE = 'Alarm Mute',
  QUALITY_RPRT_DOWNLOAD = '품질 점검 리포트 다운로드',
  EXCEL_DOWNLOAD = '엑셀 다운로드'
}


/** TOPOLOGY ID */
export const TOPOLOGY_IDS = {
  ONEVIEW: '8A8A91DA-EB6E-457E-B579-325F4284B100',
  BAEKSEOK: 'CC558D30-AD91-49A6-88B6-A13DFA12D9C8'
};

export enum ALARM_TYPE {
  CHANNEL_ALARM =  "Channel Alarm",
  DISABLED_PERSON_ALARM = "Disabled Person Alarm",
  MULTI_CHANNEL_ALARM = "Multi Channel Alarm", 
  QUETONE_ALARM =  "Quetone Alarm", 
  VOC_ALARM = "Voc Alarm"
}
