import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { IPaginationSnapshot, IReport, IReportFilter, IReportSnapshot, IResponseSnapshot } from '../../models';
import { 
  TGetReportsResult,
  TGetReportResult,
 } from "./ReportTypes";
import { dateToTimestamp, timestampToDate } from "../../utils/dateExchanger";
import { CC } from "../../commonCodes/commonCodes";

  /**
  * # Report Api 서비스
  * 
  * 서비스의 설명을 작성하세요.
  * 
  * ## 사용방법
  * 
  * ```ts
  * const service = new ReportApi();
  * 
  * ```
  */
 export class ReportApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(
    pagination: IPaginationSnapshot,
    reportFilter?: IReportFilter,
  ): Promise<TGetReportsResult> {
    try {
      const url = '/report';
      let params: any = {
        size: pagination.size,
        page: pagination.page,
      };

      if(reportFilter?.chkDtSt)
      {
        params.chkDtSt = dateToTimestamp(reportFilter.chkDtSt);
      }
      if(reportFilter?.chkDtEd)
      {
        params.chkDtEd = dateToTimestamp(reportFilter.chkDtEd);
      }

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          let reports: IReportSnapshot[] = response.data.data;
          reports.forEach((item: IReportSnapshot) => {
            item.regDt = item.regDt ? timestampToDate(Number.parseInt(item.regDt)) : '';
            item.chkDt = item.chkDt ? timestampToDate(Number.parseInt(item.chkDt)) : '';
            item.oprSendDt = item.oprSendDt ? timestampToDate(Number.parseInt(item.oprSendDt)) : '';
            item.bizSendDt = item.bizSendDt ? timestampToDate(Number.parseInt(item.bizSendDt)) : '';
          });
          const pagination: IPaginationSnapshot = response.data.pagination;
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            reports: reports,
            pagination: pagination,
          };
        }
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(chkDt: string): Promise<TGetReportResult> {
    try {
      const url: string = `/report/detail?chkDt=${chkDt}&rprtCycl=${CC.RPRT_CYCL.Day}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(response.data.data.regDt);
          response.data.data.chkDt = timestampToDate(response.data.data.chkDt);
          response.data.data.bizSendDt = timestampToDate(response.data.data.bizSendDt);
          response.data.data.oprSendDt = timestampToDate(response.data.data.oprSendDt);
          const report: IReportSnapshot = response.data.data;

          return { kind: `ok`, responseInfo: responseInfo, report: report };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }


  async excelDownload(chkDt: string, rprtType: number): Promise<TGetReportResult> {
    const url: string = `/report/excel?chkDt=${chkDt}&rprtCycl=${CC.RPRT_CYCL.Day}&rprtType=${rprtType}`;
    return this.api.download(url, 'temp');
  }
}