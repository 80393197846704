import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { Router, Route } from 'react-router-dom';
import { IResponse } from '../../models';
import { withEnvironment } from '../extensions/with-environment';
import { TGeneralApiResponse } from './../../services/api/api-problem';
import { withRootStore } from './../extensions/with-root-store';
import { createResponse } from './../response/Response';
import { toJS } from 'mobx';

/**
 * # ResponseStore
 *
 * ResponseStore을 설명하세요.
 */
export const ResponseStore = types
  .model('ResponseStore')
  // --------------------------------------------------------------------------
  .props({
    responseInfo: createResponse(),
    backLocation: types.optional(types.string, `/`),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  .actions((self) => ({
    setBackLocation: (backLocation: string) => {
      self.backLocation = backLocation;
    },
    setResponseInfo: (result: IResponse) => {
      self.responseInfo = result;
    }
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * 서버 응답이 정상인지, 에러 인지 판단.
     * 에러 -> 에러 알럿 띄움
     *     -> 세션 타임아웃 인경우, 에러 알럿 '닫기' 버튼 콜백함수로 '로그인 화면' 라우팅.
     * @param result 
     * @returns 
     */
    getResponseResult: (result: TGeneralApiResponse) => {
      self.responseInfo.reset();
      if (result.kind === 'ok') {
        // self.responseInfo = result.responseInfo as IResponse;
        
        // self.responseInfo 가 null
        self.setResponseInfo(result.responseInfo as IResponse);

        if (self.responseInfo.resultCode === 'F') {
          if (self.responseInfo.errorCode === 'CAI-0998') {
            self.rootStore.errorAlertStore.setSessionTimeout();
          } else {
            self.rootStore.errorAlertStore.setErrorAlertFromResponse(result.responseInfo as IResponse);
            return false;
          }
        }
        return true;
      } else if (result.kind === 'unauthorized') {
        self.rootStore.errorAlertStore.setSessionTimeout();
      }
    },

    getResponseResultWithOutSnackBar: (result: TGeneralApiResponse) => {
      self.responseInfo.reset();
      if (result.kind === 'ok') {
        self.responseInfo = result.responseInfo as IResponse;
        if (self.responseInfo.resultCode === 'F') {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },

    errorProcessing: (error: any) => {
      console.log('errorProcessing', error);
      self.responseInfo.reset();
    },

    // setResponse: <T>(resultType: T) => {},
  }));
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
type TResponseStore = Instance<typeof ResponseStore>;
type TResponseStoreSnapshot = SnapshotOut<typeof ResponseStore>;

export interface IResponseStore extends TResponseStore {}
export type TResponseStoreKeys = keyof TResponseStoreSnapshot & string;
export interface IResponseStoreSnapshot extends TResponseStoreSnapshot {}
export const createResponseStore = () => types.optional(ResponseStore, {} as TResponseStore);
